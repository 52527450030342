// extracted by mini-css-extract-plugin
export var campaignContainer = "l_pB d_dW";
export var campaignContainerFull = "l_pC d_dT";
export var wrapper = "l_pD d_w";
export var leftWrapper = "l_pF d_bz d_bN";
export var rightWrapper = "l_pG";
export var subtitle = "l_pH";
export var title = "l_pJ";
export var text = "l_pK d_cs";
export var inlineWrapper = "l_pL d_bC d_bG d_w";
export var btnsWrapper = "l_pM l_pL d_bC d_bG d_w d_bJ";
export var priceWrapper = "l_pN l_pL d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "l_pP";
export var disclaimerWrapper = "l_pQ d_cD";
export var mediaSlot = "l_pR";
export var listWrapper = "l_pS d_bC d_bP d_w";
export var btnWrapper = "l_d2";
export var imageWrapper = "l_pT d_fg d_Z";
export var SmallSmall = "l_pV q_pV q_qW q_rh";
export var SmallNormal = "l_pW q_pW q_qW q_rj";
export var SmallLarge = "l_pX q_pX q_qW q_rf";
export var textLeft = "l_dv";
export var textCenter = "l_dw";
export var textRight = "l_dx";
export var alignLeft = "l_pY";
export var alignCenter = "l_bP";
export var alignRight = "l_pZ";