// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "v_s6 d_bD";
export var storyRowWrapper = "v_hx d_hx d_bK";
export var storyLeftWrapper = "v_s7 d_bz d_bP";
export var storyWrapperFull = "v_s8 d_cD";
export var storyWrapperFullLeft = "v_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "v_mS d_hy";
export var storyLeftWrapperCenter = "v_s9 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "v_tb d_hF";
export var storyHeader = "v_tc d_hD d_w d_cs";
export var storyHeaderCenter = "v_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "v_hB d_hB d_by d_dw";
export var storyBtnWrapper = "v_td d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "v_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "v_pT d_fg d_Z";
export var imageWrapperFull = "v_tf d_w d_H d_bf d_Z";
export var SubtitleSmall = "v_qC q_qC q_qW q_q6";
export var SubtitleNormal = "v_qD q_qD q_qW q_q7";
export var SubtitleLarge = "v_qF q_qF q_qW q_q8";
export var textLeft = "v_dv";
export var textCenter = "v_dw";
export var textRight = "v_dx";