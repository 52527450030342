// extracted by mini-css-extract-plugin
export var carouselContainer = "D_vr d_w d_H d_bf d_Z";
export var carouselContainerCards = "D_vs D_vr d_w d_H d_bf d_Z";
export var carouselContainerSides = "D_vt d_w d_H d_Z";
export var prevCarouselItem = "D_vv d_w d_H d_0 d_k";
export var prevCarouselItemL = "D_vw D_vv d_w d_H d_0 d_k";
export var moveInFromLeft = "D_vx";
export var prevCarouselItemR = "D_vy D_vv d_w d_H d_0 d_k";
export var moveInFromRight = "D_vz";
export var selectedCarouselItem = "D_vB d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "D_vC D_vB d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "D_vD D_vB d_w d_H d_Z d_bf";
export var nextCarouselItem = "D_vF d_w d_H d_0 d_k";
export var nextCarouselItemL = "D_vG D_vF d_w d_H d_0 d_k";
export var nextCarouselItemR = "D_vH D_vF d_w d_H d_0 d_k";
export var arrowContainer = "D_vJ d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "D_vK D_vJ d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "D_vL D_vK D_vJ d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "D_vM D_vJ d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "D_vN";
export var nextArrowContainerHidden = "D_vP D_vM D_vJ d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "D_kG d_0";
export var prevArrow = "D_vQ D_kG d_0";
export var nextArrow = "D_vR D_kG d_0";
export var carouselIndicatorContainer = "D_vS d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "D_vT d_w d_bz d_bF";
export var carouselText = "D_vV d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "D_vW D_vV d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "D_vX d_b7";
export var carouselIndicator = "D_vY D_vX d_b7";
export var carouselIndicatorSelected = "D_vZ D_vX d_b7";
export var arrowsContainerTopRight = "D_v0 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "D_v1 d_0 d_bl d_bC";
export var arrowsContainerSides = "D_v2 d_0 d_bl d_bC";
export var smallArrowsBase = "D_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "D_v4 D_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "D_v5 D_v4 D_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "D_v6 D_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "D_v7 D_v6 D_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "D_qz";
export var iconSmall = "D_v8";
export var multipleWrapper = "D_v9 d_bC d_bF d_bf";
export var multipleImage = "D_wb d_bC";
export var sidesPrevContainer = "D_wc D_v4 D_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "D_wd D_v4 D_v3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";