// extracted by mini-css-extract-plugin
export var articleWrapper = "m_p0";
export var articleText = "m_p1 d_dv d_cs";
export var header = "m_p2 d_Z d_w";
export var headerImageWrapper = "m_p3 d_bd d_0 d_8 d_7 d_4 d_9 d_bk d_bR";
export var headerGradient = "m_p4 d_bd d_0 d_8 d_7 d_4 d_9";
export var headerGradientOverlay = "m_p5 d_bd d_0 d_8 d_7 d_4 d_9";
export var headerContentWrapper = "m_p6 d_bz d_bP d_bD d_bJ d_bl d_Z";
export var headerContentWrapperAlt = "m_p7 d_bz d_bP d_bD d_bJ d_bl d_Z";
export var contentWrapper = "m_mS d_w";
export var dateTag = "m_p8 d_cf d_cr d_dq d_w";
export var icon1 = "m_p9 d_cP";
export var icon2 = "m_qb d_cP d_cJ";
export var tagBtn = "m_qc d_b1 d_cD d_dp d_dB";
export var headerText = "m_qd d_w d_cf d_cs d_bW";
export var center = "m_qf d_dw";
export var videoIframeStyle = "m_qg d_d5 d_w d_H d_by d_b1 d_R";
export var articleImageWrapper = "m_qh d_cs d_Z";
export var articleImageWrapperIcon = "m_qj d_cs d_Z";
export var articleRow = "m_qk d_bD";
export var quoteWrapper = "m_gQ d_bC d_bP d_cs";
export var quoteBar = "m_ql d_H";
export var quoteText = "m_qm";
export var authorBox = "m_qn d_w";
export var authorRow = "m_qp d_bD d_bW d_cy";
export var separator = "m_qq d_w";
export var line = "m_fk d_w d_fk d_cY d_cy";
export var authorImage = "m_ln d_b7 d_Z d_w d_ct";
export var authorText = "m_qr d_ch d_ct";
export var masonryImageWrapper = "m_qs";
export var bottomSeparator = "m_qt d_w d_cy";
export var linksWrapper = "m_qv d_ch d_dv";
export var comments = "m_qw d_bD d_cy";
export var sharing = "m_qx d_bz d_bD d_bJ";
export var shareText = "m_qy d_w d_dw";
export var icon = "m_qz";
export var customRow = "m_qB d_bD d_Z";
export var text = "m_pK";
export var SubtitleSmall = "m_qC";
export var SubtitleNormal = "m_qD";
export var SubtitleLarge = "m_qF";